<template>
  <j-tile
    class="mb-4"
    :kicker="kicker"
    :headline="announcement.title"
    :headline-icon="headlineIcon"
    :description="announcement.body"
  >
    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Link -->
    <template #link v-if="announcement.get('body')">
      <router-link :to="{ name: 'announcements-view', params: { tag19: announcement.tag19 }}">
        {{ announcement.comment_count > 0 ? `Comments (${announcement.comment_count})` : 'Add Comment' }}
      </router-link>
    </template>

    <!-- Footer -->
    <template #footer v-if="!announcement.isNew()">

      <v-list-item class="grow pl-0">
        <v-list-item-avatar>
          <j-avatar
            :source="announcement.author_avatar"
            :alt="announcement.author_initials"
            size="35"
            class="jexy-avatar"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="jexy-author">
            {{ announcement.author }}
          </v-list-item-title>
        </v-list-item-content>

        <v-row
          align="center"
          justify="end"
        >
          <span class="jexy-timestamp subheading">
            {{ announcement.created_at | longDate }}
          </span>
        </v-row>
      </v-list-item>
    </template>
  </j-tile>
</template>

<script>

export default {
  name: 'AnnouncementTile',
  // -------------
  // Properties ==
  // -------------
  props: {
    announcement: {
      type: Object,
      default: null
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    kicker () {
      return this.announcement.section_slug === 'publicist-announcements' ? 'Publicists' : ''
    },
    headlineIcon () {
      return this.announcement.stage === 'draft' ? 'edit' : ''
    }
  }
}
</script>
