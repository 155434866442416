<template>

  <!-- Announcements Index View -->
  <j-page
    title="Announcements"
    :loading="loading"
    id="announcements-view"
  >
    <template #actions>

      <!-- Add Announcement Button -->
      <j-button
        v-if="$user.isAdmin"
        type="icon"
        help="Add an announcement to the site"
        icon="plus"
        :to="{ name: 'announcements-create' }"
      />

    </template>

    <!-- Main Column -->
    <v-col>

      <!-- Announcements -->
      <AnnouncementTile
        v-for="announcement in announcements.getModels()"
        :key="announcement.tag19"
        :announcement="announcement"
        class="mb-4"
      >

        <!-- Actions -->
        <template #actions>

          <!-- Announcement Index Button -->
          <j-button
            type="icon"
            help="View announcement"
            icon="eye"
            button-class="float-right"
            @action="handleView(announcement)"
          />

        </template>
      </AnnouncementTile>

    </v-col>
  </j-page>
</template>

<script>

// Components
import AnnouncementTile from '@/components/widgets/announcements/AnnouncementTile'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapState, mapActions } = createNamespacedHelpers('announcements')

export default {
  name: 'AnnouncementsIndex',
  // -------------
  // Components ==
  // -------------
  components: {
    AnnouncementTile
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.fetchAnnouncements()
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Announcements'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'announcements'
    ]),
    ...mapGetters([
      'loading'
    ])
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'fetchAnnouncements'
    ]),
    handleView(announcement) {
      this.$router.push({ name: 'announcements-view', params: { tag19: announcement.get('tag19') }})
    }
  }
}
</script>
